<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="ion-android-add-circle"
      @click="openAddPopup()"
    >
      {{ $t('AGENT_MGMT.HEADER_BTN_TXT') }}
    </woot-button>

    <div></div>

    <!-- List Agents -->
    <div class="row">
      <div class="small-8 columns with-right-space ">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('AGENT_MGMT.LOADING')"
        />
        <div class="overflow-horizontal" v-else>
          <p v-if="!agentList.length">
            {{ $t('AGENT_MGMT.LIST.404') }}
          </p>
          <table v-else class="woot-table">
            <tbody>
              <tr v-for="(agent, index) in agentList" :key="agent.email">
                <!-- Gravtar Image -->
                <td>
                  <thumbnail
                    :src="agent.thumbnail"
                    class="columns"
                    :username="agent.name"
                    size="40px"
                    :status="agent.availability_status"
                  />
                </td>
                <!-- Agent Name + Email -->
                <td>
                  <span class="agent-name">{{ agent.name }}</span>
                  <span>{{ agent.email }}</span>
                </td>
                <!-- Agent Role + Verification Status -->
                <td>
                  <span class="agent-name">
                    {{
                      $t(`AGENT_MGMT.AGENT_TYPES.${agent.role.toUpperCase()}`)
                    }}
                  </span>
                  <span v-if="agent.confirmed">
                    {{ $t('AGENT_MGMT.LIST.VERIFIED') }}
                  </span>
                  <span v-if="!agent.confirmed">
                    {{ $t('AGENT_MGMT.LIST.VERIFICATION_PENDING') }}
                  </span>
                </td>
                <!-- Actions -->
                <td>
                  <div class="button-wrapper">
                    <woot-button v-if="!agent.confirmed"
                      variant="link"
                      color-scheme="secondary"
                      icon="ion-email"
                      class-names="grey-btn"
                      @click="sendMail(agent)"
                    >
                    {{ $t('AGENT_MGMT.RESEND.TEXT') }}
                    </woot-button>
                    <woot-button
                      v-if="showEditAction(agent)"
                      variant="link"
                      color-scheme="secondary"
                      icon="ion-edit"
                      class-names="grey-btn"
                      @click="openEditPopup(agent)"
                    >
                      {{ $t('AGENT_MGMT.EDIT.BUTTON_TEXT') }}
                    </woot-button>
                    <woot-button
                      v-if="showDeleteAction(agent)"
                      variant="link"
                      color-scheme="secondary"
                      icon="ion-close-circled"
                      class-names="grey-btn"
                      :is-loading="loading[agent.id]"
                      @click="openDeletePopup(agent, index)"
                    >
                      {{ $t('AGENT_MGMT.DELETE.BUTTON_TEXT') }}
                    </woot-button>
<!--                    <router-link-->
<!--                        :to="addAccountScoping(`settings/agents/${agent.id}/histories`)"-->
<!--                    >-->
<!--                      <woot-button-->
<!--                          v-if="showViewHistoriesAction(agent)"-->
<!--                          :loading="loading[agent.id]"-->
<!--                          icon-class="ion-clock"-->
<!--                          button-class="link hollow grey-btn"-->
<!--                      >-->
<!--                        {{ $t('AGENT_MGMT.VIEW_TRACKS.BUTTON_TEXT') }}-->
<!--                      </woot-button>-->
<!--                    </router-link>-->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="small-4 columns">
        <span
          v-html="
            useInstallationName(
              $t('AGENT_MGMT.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
<!--    <histories
      v-if="showHistories"
      :show.sync="showHistories"
      :on-close="closeHistories"
      :agent="selectedAgent"
    />-->
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-agent :on-close="hideAddPopup" />
    </woot-modal>
    <!-- Edit Agent -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-agent
        v-if="showEditPopup"
        :id="currentAgent.id"
        :name="currentAgent.name"
        :type="currentAgent.role"
        :email="currentAgent.email"
        :on-close="hideEditPopup"
        :limits="currentAgent.limits"
        :availability="currentAgent.availability_status"
      />
    </woot-modal>
    <!-- Delete Agent -->
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('AGENT_MGMT.DELETE.CONFIRM.TITLE')"
      :message="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import Thumbnail from '../../../../components/widgets/Thumbnail';
import AddAgent from './AddAgent';
import EditAgent from './EditAgent';
import accountMixin from '../../../../mixins/account';
import Histories from './Histories';
import WootButton from "../../../../components/ui/WootButton";

export default {
  components: {
    WootButton,
    AddAgent,
    EditAgent,
    Thumbnail,
    Histories,
  },
  mixins: [globalConfigMixin, accountMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      agentAPI: {
        message: '',
      },
      currentAgent: {},
      showHistories: false,
      selectedAgent: {},
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
      getAgentLimit: 'agents/getAgentLimit'
    }),
    deleteConfirmText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.YES')} ${
        this.currentAgent.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.NO')} ${
        this.currentAgent.name
      }`;
    },
    deleteMessage() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.currentAgent.name
      } ?`;
    },
  },
  mounted() {
    this.$store.dispatch('agents/get').then(() => {console.log(this.currentAgent);});
    this.getAgentsLimit();
  },
  methods: {
    showEditAction(agent) {
      return this.currentUserId !== agent.id;
    },
    showDeleteAction(agent) {
      if (this.currentUserId === agent.id) {
        return false;
      }

      if (!agent.confirmed) {
        return true;
      }

      if (agent.role === 'administrator') {
        return this.verifiedAdministrators().length !== 1;
      }
      return true;
    },
    showViewHistoriesAction(agent) {
      return this.currentUserId !== agent.id;
    },
    verifiedAdministrators() {
      return this.agentList.filter(
        agent => agent.role === 'administrator' && agent.confirmed
      );
    },
    // Edit Function
    openAddPopup() {
       if (this.agentList.length > this.getAgentLimit.total_agent) {
         this.showAlert(this.$t('AGENT_MGMT.ADD.API.LIMIT_ERROR_MESSAGE'));
       }else {
         this.showAddPopup = true;
       }
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Function
    openEditPopup(agent) {
      this.showEditPopup = true;
      this.currentAgent = agent;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Function
    openDeletePopup(agent) {
      this.showDeletePopup = true;
      this.currentAgent = agent;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentAgent.id] = true;
      this.closeDeletePopup();
      this.deleteAgent(this.currentAgent.id);
    },
    async deleteAgent(id) {
      try {
        await this.$store.dispatch('agents/delete', id);
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.ERROR_MESSAGE'));
      }
    },
    // Get Agent for histories
    openHistories(agent) {
      this.showHistories = true;
      this.selectedAgent = agent;
    },
    closeHistories() {
      this.showHistories = false;
      this.selectedAgent = {};
    },
    // Show SnackBar
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.currentAgent.id] = false;
      this.currentAgent = {};
      // Show message
      this.agentAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    async getAgentsLimit() {
      await this.$store.dispatch('agents/getLimits','EMAIL')
    },
    // resend verification email to agent
    async sendMail(agent) {
      try {
        await this.$store.dispatch('agents/resendConfirmation', agent.id);
        this.showAlert(this.$t('AGENT_MGMT.RESEND.EMAIL_SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.RESEND.ERROR_MESSAGE'));
      }
    }
  },
};
</script>
<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.overflow-horizontal {
  overflow-x: auto;
}

</style>
