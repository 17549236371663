import Vue from 'vue';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import AgentAPI from '../../api/agents';
import AgentHistoriesAPI from '../../api/agent_histories';
import InboxesAPI from '../../api/inboxes';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  records_status: [],
  agent_limit: [],
  calendar_info: [],
  aiAgents: [],
};

export const getters = {
  getAgents($state) {
    return $state.records;
  },
  getAiAgents($state) {
    return $state.aiAgents;
  },
  getAiAgentById: ($state) => (id) => {
    return $state.aiAgents.find(agent => agent.id == id);
  },
  getVerifiedAgents($state) {
    return $state.records.filter(record => record.confirmed);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getAgentHistories($state) {
    return $state.records_status;
  },
  getAgentLimit($state) {
    if (!$state.agent_limit) {
      return 0;
    }
    return $state.agent_limit;
  },
  getCalendars($state) {
    return $state.calendar_info;
  },
  getAgentStatus($state) {
    let status = {
      online: $state.records.filter(
        agent => agent.availability_status === 'online'
      ).length,
      busy: $state.records.filter(agent => agent.availability_status === 'busy')
        .length,
      offline: $state.records.filter(
        agent => agent.availability_status === 'offline'
      ).length,
    };
    return status;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AgentAPI.get();
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      commit(types.default.SET_AGENTS, response.data);
    } catch (error) {
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },
  create: async ({ commit }, agentInfo) => {
    commit(types.default.SET_AGENT_CREATING_STATUS, true);
    try {
      const response = await AgentAPI.create(agentInfo);
      commit(types.default.ADD_AGENT, response.data);
      commit(types.default.SET_AGENT_CREATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_CREATING_STATUS, false);
      throw error;
    }
  },
  update: async ({ commit }, { id, ...agentParams }) => {
    commit(types.default.SET_AGENT_UPDATING_STATUS, true);
    try {
      const response = await AgentAPI.update(id, agentParams);
      commit(types.default.EDIT_AGENT, response.data);
      commit(types.default.SET_AGENT_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_UPDATING_STATUS, false);
      throw new Error(error);
    }
  },

  updatePresence: async ({ commit }, data) => {
    commit(types.default.SET_AGENT_UPDATING_STATUS, true);
    commit(types.default.UPDATE_AGENTS_PRESENCE, data);
    commit(types.default.SET_AGENT_UPDATING_STATUS, false);
  },

  delete: async ({ commit }, agentId) => {
    commit(types.default.SET_AGENT_DELETING_STATUS, true);
    try {
      await AgentAPI.delete(agentId);
      commit(types.default.DELETE_AGENT, agentId);
      commit(types.default.SET_AGENT_DELETING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_DELETING_STATUS, false);
      throw new Error(error);
    }
  },

  getHistories: async ({ commit }, agentId) => {
    try {
      const response = await AgentHistoriesAPI.show(agentId);
      commit(types.default.SET_AGENT_HISTORIES, response.data);
      return response.data;
    } catch (error) {
      // Ignore error
    }
  },
  getLimits: async ({ commit }, inboxType) => {
    try {
      const response = await AgentAPI.getAgentLimits(inboxType);
      commit(types.default.SET_AGENT_LIMITS, response.data);
      return response.data;
    } catch (error) {
      console.log('error: get inbox agent limit');
    }
  },
  googleAuth: async ({ commit }, data) => {
    try {
      await AgentAPI.google_auth_save(data);
    } catch (error) {
      console.log(error);
    }
  },
  updateGoogleAuth: async ({ commit }, data) => {
    try {
      await AgentAPI.update_google_auth(data);
    } catch (error) {
      console.log(error);
    }
  },
  signOutGoogle: async ({ commit }) => {
    try {
      await AgentAPI.sign_out_google();
    } catch (error) {
      console.log(error);
    }
  },
  saveEvent: async ({ commit }, data) => {
    try {
      await AgentAPI.save_event_calendar(data);
    } catch (error) {
      console.log(error);
    }
  },
  updateEvent: async ({ commit }, data) => {
    try {
      await AgentAPI.update_event_calendar(data);
    } catch (error) {
      console.log(error);
    }
  },
  saveCalendar: async ({ commit }, data) => {
    try {
      const response = await AgentAPI.save_secondary_calendar(data);
      commit(types.default.ADD_CALENDAR, response.data);
    } catch (error) {
      console.log(error);
    }
  },
  getUserCalendars: async ({ commit }) => {
    try {
      const response = await AgentAPI.get_calendar();
      commit(types.default.SET_CALENDAR_INFO, response.data);
      return response.data;
    } catch (error) {
      console.log('error: get inbox agent limit');
    }
  },
  getAccountCalendar: async ({ commit }, data) => {
    try {
      await AgentAPI.get_account_calendar(data);
    } catch (error) {
      console.log(error);
    }
  },
  deleteCalendar: async ({ commit }, calendarId) => {
    try {
      await AgentAPI.delete_calendar(calendarId);
      commit(types.default.DELETE_CALENDAR, calendarId);
    } catch (error) {
      throw new Error(error);
    }
  },
  resendConfirmation: async ({ commit }, agentId) => {
    try {
      await AgentAPI.confirmation_email(agentId);
    } catch (error) {
      throw new Error(error);
    }
  },

  jwtToken: async ({ commit }, data) => {
    try {
      const response = await AgentAPI.users_token(data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  generateLink: async ({ commit }, data) => {
    try {
      const response = await AgentAPI.generate_link(data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  createAIAgent: async ({ commit }, data) => {
    try {
      const response = await AgentAPI.generate_ai_agent(data);
      commit(types.default.ADD_AI_AGENT, response.data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  updateAIAgent: async ({ commit }, data) => {
    try {
      const response = await AgentAPI.edit_ai_agent(data);
      commit(types.default.EDIT_AI_AGENT, response.data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  updateWebContent: async ({ commit }, data) => {
    try {
      const response = await AgentAPI.update_web_content(data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  additionalSettings: async ({ commit }, data )=> {
    try {
      const response = await AgentAPI.additional_settings(data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  getAIAgents: async ({ commit }) => {
    try {
      const response = await AgentAPI.get_ai_agent();
      commit(types.default.SET_AI_AGENT, response.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  deleteAIAgent: async ({ commit }, id) => {
    try {
      await AgentAPI.delete_ai_agent(id);
      commit(types.default.DELETE_AI_AGENT, id);
    } catch (error) {
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_AGENT_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_AGENT_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_AGENT_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_AGENT_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },

  [types.default.SET_AGENTS]: MutationHelpers.set,
  [types.default.ADD_AGENT]: MutationHelpers.create,
  [types.default.EDIT_AGENT]: MutationHelpers.update,
  [types.default.DELETE_AGENT]: MutationHelpers.destroy,
  [types.default.UPDATE_AGENTS_PRESENCE]: MutationHelpers.updatePresence,

  [types.default.SET_AGENT_HISTORIES]($state, data) {
    $state.records_status = data;
  },
  [types.default.SET_AGENT_LIMITS]($state, data) {
    $state.agent_limit = data;
  },
  [types.default.SET_CALENDAR_INFO]($state, data) {
    $state.calendar_info = data;
  },
  [types.default.ADD_CALENDAR]($state, data) {
    $state.calendar_info.push(data);
  },
  [types.default.DELETE_CALENDAR]($state, id) {
    let index = $state.calendar_info.findIndex(calendar => calendar.id === id);
    $state.calendar_info.splice(index, 1);
  },
  [types.default.ADD_AI_AGENT]($state, data) {
    $state.aiAgents.push(data);
  },
  [types.default.EDIT_AI_AGENT]($state, data) {
    $state.aiAgents.forEach((aiagent, index) => {
      if (aiagent.id === data.id) {
        Vue.set($state.aiAgents, index, { ...$state.aiAgents[index], ...data });
      }
    });
  },
  [types.default.DELETE_AI_AGENT]($state, id) {
    let index = $state.aiAgents.findIndex(aiAgent => aiAgent.id === id);
    $state.aiAgents.splice(index, 1);
  },
  [types.default.SET_AI_AGENT]($state, data) {
    $state.aiAgents = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
