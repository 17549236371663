/* global axios */
import ApiClient from './ApiClient';

export const buildContactParams = (page, sortAttr, label, search) => {
  let params = `include_contact_inboxes=false&page=${page}&sort=${sortAttr}`;
  if (search) {
    params = `${params}&q=${search}`;
  }
  if (label) {
    params = `${params}&labels[]=${label}`;
  }
  return params;
};

class ContactAPI extends ApiClient {
  constructor() {
    super('contacts', { accountScoped: true });
  }

  get(page, sortAttr = 'name', label = '') {
    let requestURL = `${this.url}?${buildContactParams(
      page,
      sortAttr,
      label,
      ''
    )}`;
    return axios.get(requestURL);
  }

  getConversations(contactId) {
    return axios.get(`${this.url}/${contactId}/conversations`);
  }

  getContactableInboxes(contactId) {
    return axios.get(`${this.url}/${contactId}/contactable_inboxes`);
  }

  getContactLabels(contactId) {
    return axios.get(`${this.url}/${contactId}/labels`);
  }

  updateContactLabels(contactId, labels) {
    return axios.post(`${this.url}/${contactId}/labels`, { labels });
  }

  search(search = '', page = 1, sortAttr = 'name', label = '') {
    let requestURL = `${this.url}/search?${buildContactParams(
      page,
      sortAttr,
      label,
      search
    )}`;
    return axios.get(requestURL);
  }

  importContacts(file) {
    const formData = new FormData();
    formData.append('import_file', file);
    return axios.post(`${this.url}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

    exportContacts() {
        return axios.get(`${this.url}/export_contacts`);
    }

    getContactLabel(contact_id) {
        return axios.get(`${this.url}/contact_label/${contact_id}.json`);
    }

    fetchTransactionHistories(contactId) {
        return axios.get(`${this.url}/fetch_transaction_histories.json?q=${contactId}`);
    }

    fetchEvent(id) {
        return axios.get(`/app/event/${id}/details.json`);
    }

    getContactMeetings(id) {
        return axios.get(`${this.url}/schedule_list.json?q=${id}`)
    }

    getNameByPhoneNumber(phoneNumber) {
        return axios.get(`${this.url}/get_name_by_phone_number`, {
            params: {
                phone_number: phoneNumber
            }
        })
    }
}

export default new ContactAPI();
